import Macintosh from "./components/Macintosh";

function App() {
  return (
    <div className="flex items-center justify-center h-screen w-screen bg-primary dark:bg-primary-dark">
      <p className="absolute top-0 left-0 p-2 hover:underline">Kuruje.xyz</p>
      <div className="flex flex-col w-full justify-center items-center">
        <Macintosh/>
        <p className="text-3xl">Nothing to see here</p>
        <p className="text-l">except a Macintosh</p>
      </div>
   </div>
  );
}

export default App;

