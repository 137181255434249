import { Canvas } from '@react-three/fiber'
import { useGLTF, Stage, PresentationControls } from "@react-three/drei";
import { Suspense } from 'react';

import { Html, useProgress } from '@react-three/drei'

function Loader() {
    const { progress } = useProgress()
    return <Html center>{Math.round(progress)} % loaded</Html>
}

useGLTF.preload('/macintosh.glb');

const Model = (props) => {
    const { scene } = useGLTF('/macintosh.glb')
    return <primitive object={scene} {...props} />
}

const Macintosh = () => {
    return (
        <div className='w-full h-[150px] lg:h-[300px] bg-transparent self-center'>
            <Canvas camera={{ fov: 45 }}>
                <Suspense fallback={<Loader/>}>
                    <PresentationControls speed={1.5} global snap polar={[0, 0]} rotation={[0, 0.1, 0]}>
                        <Stage environment={"sunset"}>
                            <Model />
                        </Stage>
                    </PresentationControls>
                </Suspense>
            </Canvas>
        </div>
    )
}

export default Macintosh
